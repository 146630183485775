import { MutationTree } from 'vuex';
import { ProjectManagementState } from '@/store/ProjectManagement/ProjectManagementRoot';

import { IPMChart, IPMChartVersion } from '@/ship/Models/IProjectManagement';
import { ChartVersion } from '@/ship/Models/ProjectManagement';

export const mutations: MutationTree<ProjectManagementState> = {
    SET_CHART_BY_ID(state, chart: IPMChart) {
        state.chart = chart;
    },

    UPDATE_CHART_BY_ID(state, chart: IPMChart) {
        if (state.chart) {
            const versions = state.chart.versions;
            state.chart = { ...chart, versions };
        }
    },

    UPDATE_CHART_VERSION_BY_ID(state, version: IPMChartVersion) {
        if (state.chart) {
            if (state.chart.versions) {
                const item = state.chart.versions.data.find(({ id }) => id === version.id);

                if (item) Object.assign(item, version);
            }
        }
    },

    REMOVE_CHART_VERSION_BY_ID(state, versionId: number) {
        if (state.chart) {
            const versions = { data: [...state.chart.versions!.data].filter(({ id }) => id !== versionId) };
            state.chart = { ...state.chart, versions };
        }
    },

    CLEAR_CHART(state) {
        state.chart = null;
    },

    ADD_CHART_VERSION(state, version: IPMChartVersion) {
        if (state.chart) {
            const versions = {
                data: [...state.chart.versions!.data, version],
            };
            state.chart = { ...state.chart, versions };
        }
    },

    RESET_CHART(state) {
        state.chart = null;
    },

    SET_STRUCTURE(state, structure: ChartVersion) {
        state.structure = structure;
    },
};
export default mutations;
