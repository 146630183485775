import { GetRequestParams } from '@/ship/Requests/RequestParams';
import Http from '@/ship/Http';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import {
    IPMChart,
    IPMChartBpc,
    IPMChartDpc,
    IPMChartDpcExec,
    IPMChartDpcExecTask,
    IPMChartHistory,
    IPMChartVersion,
    IPMCity,
    IPMProject,
    IPMReportDeadlinesTask,
} from '@/ship/Models/IProjectManagement';
import { AxiosResponse } from 'axios';
import { ChartVersion, CreateTaskStructureBody, Structure, TemplateStructure } from '@/ship/Models/ProjectManagement';
import User from '@/ship/Models/User';

/************
 * PM Chart *
 ************/
// Получить список графиков

export const getPMChartList = (params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChart[]>>(`/project-management/charts`, { params });
};

// Создать график
export const createPMChart = (data: {
    stage: string;
    name: string;
    managerId: number | null;
    designerId: number | null;
    calendarType: number | null;
    projectId: number | null;
    startedAt: string;
    address?: string;
    contract?: string;
    permissionNumber?: string;
    chiefEngineerId?: number | null;
    permissionDate?: string;
    regionalHeadId?: number | null;
    permissionProlongation?: string;
    directorId?: number | null;
    generalContractorId?: number | null;
}) => {
    return Http.post<ITransformedValue<IPMChart>>(`/project-management/charts`, { ...data });
};

/* * Get only one chart by id.
 * Permission: search-charts
 *
 * @param id Chart id
 * @param params Optional get request parameters
 */
export const getPMChart = (id: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChart>>(`/project-management/charts/${id}`, { params });
};

// Получить сравнение по графику
export const getPMChartComparison = (id: number, data: { date?: string }, params?: GetRequestParams) => {
    return Http.post<ITransformedValue<IPMReportDeadlinesTask[]>>(
        `/project-management/charts/${id}/comparation/`,
        { data },
        { params },
    );
};

export const updatePMChart = (
    id: number,
    data: {
        name: string;
        managerId: number | null;
        designerId: number | null;
        calendarType: number | null;
        projectId: number | null;
        startedAt: string;
        type: number | null;
    },
) => {
    return Http.put<ITransformedValue<IPMChart>>(`/project-management/charts/${id}`, { ...data });
};

export const getPMChartHistory = <D = IPMChartHistory>(chartId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`project-management/charts/${chartId}/history`, { params });

export const getPMChartHistoryDpcQuart = <D = IPMChartHistory>(chartId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`project-management/detailed-schedule/${chartId}/history`, { params });

export const getPMChartHistoryDpcExec = <D = IPMChartHistory>(chartId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`project-management/detailed-schedule-execution/${chartId}/history`, { params });

export const getPMChartHistoryBpc = <D = IPMChartHistory>(chartId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`project-management/base-schedule/${chartId}/history`, { params });

// Получить список графиков по городам
export const getPMChartsByCity = (params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMCity[]>>(`/project-management/charts-by-city/`, { params });
};

// Получить список проектов с статусами по графикам
export const getPMProjectsWithChartsStatus = (params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMProject[]>>(`/project-management/projects-with-charts-status/`, { params });
};

/*******************
 * PM Chart Version
 *******************/
// Создать версию графика
export const createPMChartVersion = (data: { chartId: number; name: string; description: string }) => {
    return Http.post<ITransformedValue<IPMChartVersion>>(`/project-management/chart-versions`, { ...data });
};

// Изменить версию графика
export const updatePMChartVersion = (id: number, data: { name: string; description?: string }) => {
    return Http.put<ITransformedValue<IPMChartVersion>>(`/project-management/chart-versions/${id}`, { ...data });
};

// Копировать версию графика
export const copyPMChartVersion = (id: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChartVersion>>(`/project-management/chart-versions/${id}/copy`, { params });
};

// Перенести данные из одной версии в другую версию графика
export const transferPMChartVersion = (fromId: number, toId: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChartVersion>>(
        `/project-management/chart-versions/${fromId}/transfer/${toId}`,
        { params },
    );
};

// Получить версию графика
export const getPMChartVersion = (id: number) => {
    return Http.get<ITransformedValue<IPMChartVersion>>(`/project-management/chart-versions/${id}`);
};

// Удалить версию
export const deletePMChartVersion = (id: number) => {
    return Http.delete(`/project-management/chart-versions/${id}`);
};

/*********************
 * PM Detail Plan Chart
 *********************/
// Получить список детальных план-графиков по графику
export const getPMChartDpcList = (id: number) => {
    return Http.get<ITransformedValue<IPMChartDpc[]>>(
        `/project-management/chart/${id}/detailed-schedules?orderBy=created_at&sortedBy=desc`,
    );
};

// Создать детальный план-графиков по графику
export const createPMChartDpc = (id: number) => {
    return Http.get<ITransformedValue<IPMChartDpc>>(`/project-management/chart/${id}/detailed-schedules/create`);
};

// Удалить детальный план график
export const deletePMChartDpc = (id: number) => {
    return Http.delete(`/project-management/detailed-schedule/${id}/`);
};

// Получить детальный план-график
export const getPMChartDpc = (id: number, params?: GetRequestParams) => {
    return Http.get(`/project-management/detailed-schedule/${id}/`, { params });
};

// Изменить детальный план график
export const updatePMChartDpc = (
    id: number,
    data: {
        mainEngineerId?: number;
        branchDirectorId?: number;
        regionalHeadId?: number;
        constructionDirectorDeputyId?: number;
        generalDirectorDeputyId?: number;
        startDate?: string;
        endDate?: string;
    },
) => {
    return Http.put<ITransformedValue<IPMChartDpc>>(`/project-management/detailed-schedule/${id}/`, { ...data });
};

// Не согласовать детальный план-график
export const discardPMChartDpc = (id: number) => {
    return Http.get(`/project-management/detailed-schedule/${id}/not-agree`);
};

// Согласовать детальный план-график
export const agreePMChartDpc = (id: number) => {
    return Http.get(`/project-management/detailed-schedule/${id}/agree`);
};

// Отправить детальный план-график на согласование
export const approvePMChartDpc = (id: number) => {
    return Http.get(`/project-management/detailed-schedule/${id}/to-approval`);
};

// Подгрузка задач из графика в детальный план график
// использовать только для формирования и переформирования отчета (создания/изменения)
export const loadTasksPMChartDpc = (id: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChartDpc>>(`/project-management/detailed-schedule/${id}/load-tasks`, {
        params,
    });
};

/*********************************
 * PM Detail Plan Execution Chart
 ********************************/
// Получить список детальных план-графиков (исполнение) по графику
export const getPMChartDpcExecList = (id: number) => {
    return Http.get<ITransformedValue<IPMChartDpcExec[]>>(
        `/project-management/chart/${id}/detailed-schedules-execution?orderBy=created_at&sortedBy=desc`,
    );
};

// Создать детальный план-график (исполнение) по графику
export const createPMChartDpcExec = (id: number) => {
    return Http.get<ITransformedValue<IPMChartDpcExec>>(
        `/project-management/chart/${id}/detailed-schedules-execution/create`,
    );
};

// Удалить детальный план график (исполнение)
export const deletePMChartDpcExec = (id: number) => {
    return Http.delete(`/project-management/detailed-schedule-execution/${id}/`);
};

// Получить детальный план-график (исполнение)
export const getPMChartDpcExec = (id: number, params?: GetRequestParams) => {
    return Http.get(`/project-management/detailed-schedule-execution/${id}/`, { params });
};

// Изменить детальный план график (исполнение)
export const updatePMChartDpcExec = (
    id: number,
    data: {
        mainEngineerId?: number;
        branchDirectorId?: number;
        regionalHeadId?: number;
        constructionDirectorDeputyId?: number;
        generalDirectorDeputyId?: number;
        detailedScheduleId?: number;
    },
) => {
    return Http.put<ITransformedValue<IPMChartDpcExec>>(`/project-management/detailed-schedule-execution/${id}/`, {
        ...data,
    });
};

// Изменить задачу детального плана графика (исполнение)
export const updateTaskPMChartDpcExec = (id: number, data: { description: string | null }) => {
    return Http.put<ITransformedValue<IPMChartDpcExecTask>>(
        `/project-management/detailed-schedule-execution-task/${id}/`,
        {
            ...data,
        },
    );
};

// Не согласовать детальный план-график (исполнение)
export const discardPMChartDpcExec = (id: number) => {
    return Http.get(`/project-management/detailed-schedule-execution/${id}/not-agree`);
};

// Согласовать детальный план-график (исполнение)
export const agreePMChartDpcExec = (id: number) => {
    return Http.get(`/project-management/detailed-schedule-execution/${id}/agree`);
};

// Отправить детальный план-график (исполнение) на согласование
export const approvePMChartDpcExec = (id: number) => {
    return Http.get(`/project-management/detailed-schedule-execution/${id}/to-approval`);
};

// Подгрузка задач из графика в детальный план график (исполнение)
// использовать только для формирования и переформирования отчета (создания/изменения)
export const loadTasksPMChartDpcExec = (id: number, detailedScheduleId: number, params?: GetRequestParams) => {
    return Http.post<ITransformedValue<IPMChartDpcExec>>(
        `/project-management/detailed-schedule-execution/${id}/load-tasks`,
        {
            detailedScheduleId,
        },
        {
            params,
        },
    );
};

/*********************
 * PM Base Plan Chart
 *********************/
// Получить список базовых план-графиков по графику
export const getPMChartBpcList = (id: number) => {
    return Http.get<ITransformedValue<IPMChartBpc[]>>(
        `/project-management/chart/${id}/base-schedules?orderBy=created_at&sortedBy=desc`,
    );
};

// Создать базовый план-графиков по графику
export const createPMChartBpc = (id: number) => {
    return Http.get<ITransformedValue<IPMChartBpc>>(`/project-management/chart/${id}/base-schedules/create`);
};

// Удалить базовый план график
export const deletePMChartBpc = (id: number) => {
    return Http.delete(`/project-management/base-schedule/${id}/`);
};

// Получить базовый план-график
export const getPMChartBpc = (id: number, params?: GetRequestParams) => {
    return Http.get(`/project-management/base-schedule/${id}/`, { params });
};

// Изменить базовый план график
export const updatePMChartBpc = (
    id: number,
    data: {
        mainEngineerId?: number;
        branchDirectorId?: number;
        regionalHeadId?: number;
        constructionDirectorDeputyId?: number;
        generalDirectorDeputyId?: number;
    },
) => {
    return Http.put<ITransformedValue<IPMChartBpc>>(`/project-management/base-schedule/${id}/`, { ...data });
};

// Не согласовать базовый план-график
export const discardPMChartBpc = (id: number) => {
    return Http.get(`/project-management/base-schedule/${id}/not-agree`);
};

// согласовать базовый план-график
export const agreePMChartBpc = (id: number) => {
    return Http.get(`/project-management/base-schedule/${id}/agree`);
};

// Отправить базовый план-график на согласование
export const approvePMChartBpc = (id: number) => {
    return Http.get(`/project-management/base-schedule/${id}/to-approval`);
};

// Подгрузка задач из графика в базовый план график
// использовать только для формирования и переформирования отчета (создания/изменения)
export const loadTasksPMChartBpc = (id: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChartBpc>>(`/project-management/base-schedule/${id}/load-tasks`, {
        params,
    });
};
// Получить PDF
export const getPdfPMPlanChart = (id: number, type: string): Promise<AxiosResponse<ITransformedValue<string>>> => {
    return Http.get(`/project-management/${type}/${id}/pdf`);
};

//structure
export const getChartVersionStructure = <D = ChartVersion>(
    id: number,
    params: GetRequestParams = { include: 'tasks, executor' },
) => Http.get<ITransformedValue<D>>(`/project-management/chart-versions/${id}`, { params });

export const createTaskStructure = (data: CreateTaskStructureBody) => {
    return Http.post(`/project-management/chart-versions/tasks`, data);
};

export const copyTaskStructure = (id: number) => {
    return Http.get(`/project-management/chart-versions/tasks/${id}/copy`);
};

export const updateTaskStructure = (id: number, data: CreateTaskStructureBody) => {
    return Http.put(`/project-management/chart-versions/tasks/${id}`, data);
};

export const deleteTaskStructure = (id: number) => {
    return Http.delete(`/project-management/chart-versions/tasks/${id}`);
};

export const getTasksByVersionStructure = <D = Structure[]>(
    id: number,
    params: GetRequestParams = { search: 'type:2' },
) => Http.get<ITransformedValue<D>>(`/project-management/chart-versions/${id}/tasks`, { params });

export const updateConnectionStructure = (id: number, data: { isAutoChange: boolean; manualStartDate?: string }) => {
    return Http.put(`/project-management/chart-versions/tasks/${id}/change-links`, data);
};

export const moveTaskStructure = (
    id: number,
    data: { code: number; chartVersionId: number; parentTaskId?: number },
) => {
    return Http.put(`/project-management/chart-versions/tasks/${id}/change-position`, data);
};

export const createLinkStructure = (data: {
    type: number;
    sourceTaskId: number;
    targetTaskId: number;
    delay?: number;
}) => {
    return Http.post(`/project-management/chart-version-tasks/link`, data);
};

export const updateLinkStructure = (
    id: number,
    data: {
        type: number;
        sourceTaskId: number;
        targetTaskId: number;
        delay?: number;
    },
) => {
    return Http.put(`/project-management/chart-version-tasks/link/${id}`, data);
};

export const getTaskByIdStructure = <D = Structure>(id: number) =>
    Http.get<ITransformedValue<D>>(`/project-management/chart-version-tasks/${id}`);

export const deleteLinkStructure = (id: number) => {
    return Http.delete(`/project-management/chart-version-tasks/link/${id}`);
};

export const updateProgressStructure = (
    id: number,
    data: {
        executorId?: number;
        completeness?: number;
        duration?: number;
        comment?: string;
        stopPeriodStart?: string;
        stopPeriodEnd?: string;
    },
) => {
    return Http.put(`/project-management/chart-versions/tasks/${id}/change-progress`, data);
};

export const getHistoryStructure = <D = IPMChartHistory[]>(id: number) =>
    Http.get<ITransformedValue<D>>(`/project-management/chart-version-tasks/${id}/history`);

export const getUsersStructure = <D = User[]>() => Http.get<ITransformedValue<D>>(`/project-management/users`);

export const removeWarningStructure = (id: number) => {
    return Http.get(`/project-management/chart-version-tasks/${id}/read`);
};

export const getTemplatesStructure = <D = TemplateStructure[]>() => {
    return Http.get<ITransformedValue<D>>(`/project-management/chart-version-temples`);
};

export const applyTemplateStructure = (versionId: number, templateId: number) => {
    return Http.get(`/project-management/chart-version-temples/change/from/${versionId}/to/${templateId}`);
};

export const createWeeklyReport = (chartId: number) => {
    return Http.get(`/project-management/charts/${chartId}/weekly-report`);
};

export const getWeeklyReportList = (chartId: number) => {
    return Http.get(`/project-management/charts/${chartId}/weekly-reports?limit=0`);
};

export const getWeeklyReportById = (id: number) => {
    return Http.get(
        `/project-management/charts/weekly-report/${id}?include=photo1,photo2,photo3,photo4,photo_general_plan,tasks.photo`,
    );
};

export const updatePhotoWeeklyReport = (id: number, data: { photo_id: number | null; photo_number: number }) => {
    return Http.post(
        `/project-management/charts/weekly-report/${id}/photo-attach?include=photo1,photo2,photo3,photo4,photo_general_plan`,
        data,
    );
};

export const updateDateWeeklyReport = (id: number, data: { start_date: string; end_date: string }) => {
    return Http.put(
        `/project-management/charts/weekly-report/${id}?include=photo1,photo2,photo3,photo4,photo_general_plan`,
        data,
    );
};

export const getWeeklyReportTasks = (id: number) => {
    return Http.get(`/project-management/charts/weekly-report/${id}/load-tasks?include=tasks.photo`);
};

export const getWeeklyReportTaskById = (id: number) => {
    return Http.get(`/project-management/charts/weekly-report-tasks/${id}?include=photo`);
};

export const sendToApproveWeeklyReport = (id: number) => {
    return Http.get(
        `/project-management/charts/weekly-report/${id}/complete?include=photo1,photo2,photo3,photo4,photo_general_plan`,
    );
};

export const approveWeeklyReport = (id: number) => {
    return Http.get(
        `/project-management/charts/weekly-report/${id}/approve?include=photo1,photo2,photo3,photo4,photo_general_plan`,
    );
};

export const cancelWeeklyReport = (id: number) => {
    return Http.get(
        `/project-management/charts/weekly-report/${id}/cancel?include=photo1,photo2,photo3,photo4,photo_general_plan`,
    );
};

export const getPMChartHistoryWeeklyReport = <D = IPMChartHistory>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`project-management/charts/weekly-report/${id}/history`, { params });

export const updateDescriptionWeeklyReport = (id: number, data: { description: string; photo_ids: number[] }) => {
    return Http.put(`/project-management/charts/weekly-report-tasks/${id}?include=photo`, data);
};

export const getPdfWeeklyReport = (id: number) => {
    return Http.get(`/project-management/charts/weekly-report/${id}/pdf`);
};

export const deleteWeeklyReport = (id: number) => {
    return Http.delete(`/project-management/charts/weekly-report/${id}/`);
};
