import { IPMChart } from '@/ship/Models/IProjectManagement';
import { ChartVersion } from '@/ship/Models/ProjectManagement';

import { StoreOptions } from 'vuex';
import actions from './Actions';
import getters from './Getters';
import mutations from './Mutations';
import { namespace } from 'vuex-class';

export interface ProjectManagementState {
    chart: IPMChart | null;
    editChart: IPMChart | null;
    structure: ChartVersion | null;
}

export const state: ProjectManagementState = {
    chart: null,
    editChart: null,
    structure: null,
};

export const root: StoreOptions<ProjectManagementState> = {
    state,
    actions,
    getters,
    mutations,
};

export const ProjectManagementStore = namespace('projectManagement');
export const ProjectManagementAction = ProjectManagementStore.Action;
export const ProjectManagementGetter = ProjectManagementStore.Getter;
export const ProjectManagementMutation = ProjectManagementStore.Mutation;
export const ProjectManagementState = ProjectManagementStore.State;

export default root;
