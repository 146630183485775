import { ProjectManagementState } from '@/store/ProjectManagement/ProjectManagementRoot';
import { GetterTree } from 'vuex';

import { IPMChartVersionRow } from '@/ship/Models/IProjectManagement';

export const getters: GetterTree<ProjectManagementState, ProjectManagementState> = {
    chartVersions(state): IPMChartVersionRow[] {
        return state.chart ? [...state.chart.versions!.data].map((version) => ({ ...version, selected: false })) : [];
    },

    isStructureExist(state): boolean {
        return !!state.structure?.tasks.data.length;
    },
};
export default getters;
